.footer {
    margin-top: 50px;
    background-color: rgb(161, 161, 161);
    opacity: 0.8;
    padding: 20px 0px;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    gap: 100px;
}

.footer-link {
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.footer-link:hover {
    text-decoration: underline;
}