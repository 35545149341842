.skills-container {
    text-align: center;
    color: white;
}

.skills-container .skills-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-left: 20px;
}

.skills-container .skills-column {
    flex: 1 0 100px;
    padding: 20px 0px 0px 0px;
    max-width: 200px;
    text-align: left;
}

.skills-container .skills-column:first-child {
    margin-left: 7%;
}

.skills-container ul {
    list-style-type: none;
    padding-left: 0;
    font-weight: 500;
}

.skills-container .skills-column h2 {
    font-size: 1.3em;
    margin-bottom: 10px;
    text-decoration: underline;
}