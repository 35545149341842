/* BowlOfFishPage.css */

.bof-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
}

.bof-page-container .home-button {
    position: relative;
    top: 10px;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    border-bottom: 0.1em solid black;
    margin-bottom: 20px;
}

.bof-page-container .home-button:hover {
    background-color: rgb(116, 111, 111);
    color: white;
}