@import url(https://fonts.googleapis.com/css2?family=Outfit&display=swap);
/* Intro.css */
.intro {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
}
 
.intro img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin-right: 30px;
}

.intro .text {
    color: white;
    text-align: center;
    margin: 10px;
}

.intro .text .greeting {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.intro .text .greeting .hi {
    min-width: 10%;
    font-size: 3em;
    color: white;
    text-align: left;
}

.intro .text .greeting .name {
    margin-left: 5%;
    font-size: 3em;
    color: black;
}

.intro .text .description {
    font-weight: 500;
    justify-content: flex-end;
    text-align: left;
    margin-left: 15%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1em;
    width: 75%;
}

.intro .buttons {
    display: flex;
    justify-content: left;
}

.intro .buttons button {
    width: 10vw;
    max-width: 30vw;
    margin-left: 15%;
    border-radius: 25px;
    padding: 15px 0px;
    font-size: 1em;
    background-color: #a3a3a3;
    border: none;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    color: black;
}

.intro .buttons button:active {
    background-color: rgb(255, 255, 255);
    transform: translateY(2px);
}

@media screen and (min-width: 1500px) {
    .intro img {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 650px) {
    .intro {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .intro img {
        margin-bottom: 50px;
    }

    .intro .buttons button {
        margin-bottom: 30px;
        width: 30vw;
    }

    .intro .text .greeting .name{
        font-size: 2em;
        font-weight: 600;
    }

    .intro .text .greeting .hi{
        font-size: 2em;
        font-weight: 600;
    }
}

/* info-slider.css */
.info-slider {
    text-align: center;
    color: black;
}

.info-slider .title {
    font-size: 1.4em;
    background-color: rgb(189, 180, 180);
    border-radius: 25px;
    padding: 7px 0px;
    font-weight: bold;
}

.info-box {
    display: flex;
    justify-content: space-between;
    min-height: 380px;
    align-items: center;
    border-radius: 25px;
    padding: 20px;
    margin: 20px 0;
}

.info-box img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 30px;
    margin-left: 30px;
    padding: 10px;
}

.info-content {
    text-align: left;
    margin: 20px 0;
    color: white;
}

.info-content .subtitle {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: underline;
}

ul {
    margin-top: 0;
}

.circles {
    display: flex;
    justify-content: center;
}

.circle {
    width: 10px;
    height: 10px;
    background-color: rgb(82, 75, 75);
    border-radius: 50%;
    margin: 0 5px 10px 5px;
}

.circle.active {
    background-color: white;
}

@media (max-width: 650px) {
    .info-box {
        padding: 4px
    }
    .info-box img {
        padding: 0px;
        margin-left: 15px;
        margin-right: 15px;
    }
}
.skills-container {
    text-align: center;
    color: white;
}

.skills-container .skills-grid {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    padding-left: 20px;
}

.skills-container .skills-column {
    flex: 1 0 100px;
    padding: 20px 0px 0px 0px;
    max-width: 200px;
    text-align: left;
}

.skills-container .skills-column:first-child {
    margin-left: 7%;
}

.skills-container ul {
    list-style-type: none;
    padding-left: 0;
    font-weight: 500;
}

.skills-container .skills-column h2 {
    font-size: 1.3em;
    margin-bottom: 10px;
    text-decoration: underline;
}
.images-container {
    display: flex;
    justify-content: center;
}

.image {
    width: 200px;
    height: 200px;
    max-width: 20vw;
    max-height: 20vw;
    margin-right: 30px;
    border-radius: 50px;
    margin: 10px 10px 100px 10px;
}

@media (max-width: 650px) {
    .image {
        margin: 10px 10px 50px 10px;
    }   
}
.personal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personal .box {
    width: 80%;
    padding: 20px;
    margin: 40px 0;
    color: rgb(7, 0, 0);
    border-radius: 50px;
    text-align: center;
}

.personal .skiing {
    background-color: rgb(47, 143, 175);
}

.personal .conservation {
    background-color: rgb(204, 136, 9);
}

@media (min-width: 650px) {
    .personal {
        flex-direction: row;
        justify-content: space-around;
    }

    .personal .box {
        width: 40%;
    }
}
.footer {
    margin-top: 50px;
    background-color: rgb(161, 161, 161);
    opacity: 0.8;
    padding: 20px 0px;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    grid-gap: 100px;
    gap: 100px;
}

.footer-link {
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.footer-link:hover {
    text-decoration: underline;
}
body {
    height: 100vh;
    margin: 0;
    background: linear-gradient(to bottom, #A9A79A, #7F7F7F);
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    padding: 0 8%;
    font-family: 'Outfit', 'sans-serif';
}

.page-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.fade-in-section {
    opacity: 0;
    transition: opacity 3s ease-in-out;
}


.fade-in-section.is-visible {
    opacity: 1;
}
/* BowlOfFishPage.css */

.bof-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
}

.bof-page-container .home-button {
    position: relative;
    top: 10px;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    border-bottom: 0.1em solid black;
    margin-bottom: 20px;
}

.bof-page-container .home-button:hover {
    background-color: rgb(116, 111, 111);
    color: white;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

