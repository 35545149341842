/* info-slider.css */
.info-slider {
    text-align: center;
    color: black;
}

.info-slider .title {
    font-size: 1.4em;
    background-color: rgb(189, 180, 180);
    border-radius: 25px;
    padding: 7px 0px;
    font-weight: bold;
}

.info-box {
    display: flex;
    justify-content: space-between;
    min-height: 380px;
    align-items: center;
    border-radius: 25px;
    padding: 20px;
    margin: 20px 0;
}

.info-box img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 30px;
    margin-left: 30px;
    padding: 10px;
}

.info-content {
    text-align: left;
    margin: 20px 0;
    color: white;
}

.info-content .subtitle {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: underline;
}

ul {
    margin-top: 0;
}

.circles {
    display: flex;
    justify-content: center;
}

.circle {
    width: 10px;
    height: 10px;
    background-color: rgb(82, 75, 75);
    border-radius: 50%;
    margin: 0 5px 10px 5px;
}

.circle.active {
    background-color: white;
}

@media (max-width: 650px) {
    .info-box {
        padding: 4px
    }
    .info-box img {
        padding: 0px;
        margin-left: 15px;
        margin-right: 15px;
    }
}